// cover some element-ui styles

.el-breadcrumb__inner,
.el-breadcrumb__inner a {
  font-weight: 400 !important;
}

.cell {
  .el-tag {
    margin-right: 0px;
  }
}

.el-dialog {
  transform: none;
  left: 0;
  position: relative;
  margin: 0 auto;
}

.upload-container {
  .el-upload {
    width: 100%;

    .el-upload-dragger {
      width: 100%;
      height: 200px;
    }
  }
}

// dropdown
.el-dropdown-menu {
  a {
    display: block;
  }
}

// fix date-picker ui bug in filter-item
.el-range-editor.el-input__inner {
  display: inline-flex !important;
}

// to fix el-date-picker css style
.el-range-separator {
  box-sizing: content-box;
}

// el-button
.el-button {
  &.medium {
    width: 120px;
  }
  &.super {
    &.el-button--primary {
      color: #ffffff;
      background-color: #44b2d5;
      border-color: #44b2d5;
    }
    &.el-button--primary:hover,
    &.el-button--primary:focus {
      background-color: lighten(#44b2d5, 5%);
      border-color: lighten(#44b2d5, 5%);
    }
  }
}
.el-button--primary {
  color: #ffffff;
  background-color: var(--color-primary-d);
  border-color: var(--color-primary-d);
}
.el-button--primary:hover,
.el-button--primary:focus {
  background-color: lighten(#2369cd, 5%);
  border-color: lighten(#2369cd, 5%);
}

// sync the el-radio color background
.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #ffffff;
  background-color: var(--color-primary-d);
  border-color: var(--color-primary-d);
  box-shadow: -1px 0 0 0 var(--color-primary-d);
}

// el-message
.el-message {
  &--success {
    background-color: #e1edff;
    border-color: #cfe1ff;
    .el-icon-success {
      color: var(--color-primary);
    }
    .el-message__content {
      color: var(--color-primary);
    }
  }
}

// el-upload
.el-upload {
  // 仅默认上传样式 按钮居左
  &.el-upload-- {
    text-align: left;
  }
  + .el-upload__tip {
    color: #aaa;
  }
}

// el-dropdown-menu
.kx {
  &.el-dropdown-menu__item:focus,
  &.el-dropdown-menu__item:not(.is-disabled):hover {
    &[co-da] {
      color: var(--color-danger);
    }
  }
}

// 下拉菜单
.el-dropdown {
  cursor: pointer;
}

// el-radio
.el-radio {
  margin-right: 12px;
  &__label {
    padding-left: 5px;
  }
}

// 表格 增加超文本省略
.el-table .cell {
  white-space: nowrap;
}