/*
  vue.config.js css.loaderOptions scss 依赖  
*/
@import "../../styles/settings/index.scss";
@import "../../styles/tools/index.scss";

.ct {
  font-size: 16px;
  &__header {
    color: #aaa;
    border-bottom: 1px solid #aaa;
    margin-bottom: 10px;
  }

  &__box {
    border: 1px solid #ccc;
    padding: 10px;
  }

  &__boxInput {
    // border: 1px solid #74aefe;
    height: 36px;
    color: #aaa;
    @include center;
    justify-content: flex-start;
    // padding-left: 12px;
    font-size: 14px;
    position: relative;
    border-radius: 3px;
  }

  &__boxIcon {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    color: #3388FF;
    font-size: 36px;
    cursor: pointer;
  }
}

.addUser__text {
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    .addUser__del {
      display: inline-block;
    }
  }
}

.addUser__del {
  display: none;
}

:global {
  .myModal {
    .ant-modal-footer {
      @include center;
    }
    .ant-btn {
      width: 130px;
      height: 36px;
    }
  }
}
