/*
  vue.config.js css.loaderOptions scss 依赖  
*/
@import "../../styles/settings/index.scss";
@import "../../styles/tools/index.scss";

.panel {
  position: relative;
  box-shadow: 0 1px 3px 1px var(--color-shadow-l);
  &__icons {
    display: none;
  }
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 3px 1px var(--color-primary);
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.35);
    }
    .panel__icons {
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      @include center;
      > .anticon {
        @include hover-opacity;
      }
    }
  }
}
