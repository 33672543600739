/*
  vue.config.js css.loaderOptions scss 依赖  
*/
@import "../../styles/settings/index.scss";
@import "../../styles/tools/index.scss";

.resourceManage {
  :global {
    .ant-table-row {
      -webkit-user-drag: element;
      user-select: none;
    }
  }
}
