#app {
  .main-container {
    min-height: 100%;
    transition: margin-left 0.28s;
    margin-left: $sideBarWidth + $sideBarGap;
    position: relative;
  }

  .sidebar-container {
    transition: width 0.28s;
    box-shadow: 1px 0 3px 1px rgba(0, 0, 0, 0.2);
    width: $sideBarWidth !important;
    background-color: $menuBg;
    height: 100%;
    position: fixed;
    font-size: 0px;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
    overflow: hidden;

    // 菜单项
    .el-menu-item {
      height: 42px;
      line-height: 42px;
      margin: 6px 8px;
      border-radius: 5px;
      padding: 0 12px !important;

      &:hover {
        background-color: $menuHover !important;
      }

      // 菜单项被选中
      &.is-active {
        background-color: var(--color-primary-d) !important;
        color: white !important;
      }
    }

    // 菜单项图表
    .sidebar-icon {
      font-size: 18px;
    }

    // 二级菜单右侧下拉按钮
    .el-submenu__icon-arrow {
      top: 50%;
      transform: translateY(-50%);
      margin-top: 0;
      font-size: 16px;
    }

    // 二级菜单

    .el-submenu__title:hover,
    .el-submenu__title:focus,
    .nest-menu {
      background-color: $menuHover !important;
    }

    // 二级菜单项最小宽度
    .el-submenu {
      .el-menu-item {
        min-width: auto;
        margin-left: 24px;
      }
      &.is-opened .el-submenu__icon-arrow {
        transform: translateY(-50%) rotateZ(180deg) !important;
      }
    }

    .sidebar-logo-container.collapse {
      .sidebar-title {
        display: none;
      }
    }

    a {
      display: inline-block;
      width: 100%;
      overflow: hidden;
    }

    .svg-icon {
      margin-right: 16px;
    }

    .sub-el-icon {
      margin-right: 12px;
      margin-left: -2px;
    }

    .el-menu {
      border: none;
      height: 100%;
      width: 100% !important;
    }
  }

  .hideSidebar {
    .sidebar-container {
      width: 54px !important;
    }

    .main-container {
      margin-left: 54px;
    }

    .submenu-title-noDropdown {
      padding: 0 !important;
      text-align: center;
      position: relative;

      .el-tooltip {
        padding: 0 !important;
        text-align: center;

        .svg-icon {
          margin-left: 20px;
        }

        .sub-el-icon {
          margin-left: 19px;
        }
      }
    }

    .el-submenu {
      overflow: hidden;

      & > .el-submenu__title {
        // padding: 0 !important;
        text-align: center;

        .svg-icon {
          margin-left: 20px;
        }

        .sub-el-icon {
          margin-left: 19px;
        }

        .el-submenu__icon-arrow {
          display: none;
        }
      }
    }

    .el-menu--collapse {
      .el-submenu {
        & > .el-submenu__title {
          & > span {
            height: 0;
            width: 0;
            overflow: hidden;
            visibility: hidden;
            display: inline-block;
          }
        }
      }
    }
  }

  .el-menu--collapse .el-menu .el-submenu {
    min-width: $sideBarWidth !important;
  }

  // mobile responsive
  .mobile {
    .main-container {
      margin-left: 0px;
    }

    .sidebar-container {
      transition: transform 0.28s;
      width: $sideBarWidth !important;
    }

    &.hideSidebar {
      .sidebar-container {
        pointer-events: none;
        transition-duration: 0.3s;
        transform: translate3d(-$sideBarWidth, 0, 0);
      }
    }
  }

  .withoutAnimation {
    .main-container,
    .sidebar-container {
      transition: none;
    }
  }
}

// when menu collapsed
.el-menu--vertical {
  & > .el-menu {
    .svg-icon {
      margin-right: 16px;
    }
    .sub-el-icon {
      margin-right: 12px;
      margin-left: -2px;
    }
  }

  .nest-menu .el-submenu > .el-submenu__title,
  .el-menu-item {
    &:hover {
      // you can use $subMenuHover
      background-color: $menuHover !important;
    }
  }

  // the scroll bar appears when the subMenu is too long
  > .el-menu--popup {
    max-height: 100vh;
    overflow-y: auto;

    &::-webkit-scrollbar-track-piece {
      background: #d3dce6;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #99a9bf;
      border-radius: 20px;
    }
  }
}

// sidebar 样式
.sidebar-imgIcon {
  width: 20px;
  height: 20px;
  &.terminal-manage {
    transform: scale(1.3);
  }
}
.sidebar-title {
  padding-left: 10px;
}
