/*
  vue.config.js css.loaderOptions scss 依赖  
*/
@import "../../styles/settings/index.scss";
@import "../../styles/tools/index.scss";

.ct {
  &-main {
    padding: 8px 16px 24px;
    font-size: var(--font-size);
    display: flex;
  }
  &-item {
    display: flex;
    justify-content: flex-start;
    padding: 5px 0;
    p {
      &:nth-child(1) {
        min-width: 110px;
        margin-right: 12px;
        color: var(--color-secondary);
        text-align: right;
      }
      &:nth-child(2) {
        margin-right: 6px;
      }
      &.money {
        font-size: var(--font-size-l);
        color: var(--color-warn) !important;
      }
    }
    &-cover {
      border: 1px solid #eee;
      @include simple-shadow;
    }
  }
}

.wx-avatar {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 32px;
  @include simple-shadow;
}

.search-icon {
  @include center;
  width: 28px;
  height:28px;
  border-radius: 16px;
  background-color: var(--color-primary);
  @include hover-opacity;
  margin-left: 8px;
}