/*
  扩展样式类名：
  开设命名前缀： kx-
*/
// 盒 通用布局
.kx-ct {
  @include innerRouterStyle;
  // 盒内 顶部导航 通用布局
  &-nav {
    width: 100%;
    display: inline-block;
    height: $workspace-navbar-height;
    > div,
    > p,
    > button {
      display: inline-block;
      vertical-align: middle;
    }
    div + div,
    div + button,
    button + div,
    button + button {
      margin-left: 8px;
    }
  }
}

// 提示文字
.kx-tip {
  font-size: var(--font-size-s);
  color: var(--font-color-primary-l);
  padding: 6px 3px;
}

// 文字式按钮
.kx-text-btn {
  padding: 0px 8px;
  font-size: 14px;
  color: var(--color-primary-d);
  @include hover-opacity();
}

// 详情展示
.kx-detail {
  min-width: 320px;
  min-height: 220px;
  &.h-auto {
    min-height: auto;
  }
   section {
    display: flex;
    font-size: var(--font-size);
    padding: 8px 0;
    p:first-child {
      text-align: right;
      flex: 0 0 120px;
      width: 120px;
      padding-right: 16px;
      color: var(--font-color-primary);
      &::after {
        content: ":";
      }
    }
  }
}

.kx-label {
  font-size: var(--font-size);
  color: var(--font-color-primary-l);
  margin: 12px 0;
  padding-bottom: 6px;
  border-bottom: 1px solid var(--color-secondary-l);
}

