/*
  vue.config.js css.loaderOptions scss 依赖  
*/
@import "../../styles/settings/index.scss";
@import "../../styles/tools/index.scss";

.ct {
  &-main {
    padding: 8px 16px 24px;
    font-size: var(--font-size);
    display: flex;
  }
  &-item {
    display: flex;
    justify-content: flex-start;
    padding: 5px 0;
    p {
      &:nth-child(1) {
        margin-right: 12px;
        color: var(--color-secondary);
      }
      &:nth-child(2) {
        margin-right: 6px;
      }
      &.money {
        font-size: var(--font-size-l);
        color: var(--color-warn) !important;
      }
    }
    &-cover {
      border: 1px solid #eee;
      @include simple-shadow;
    }
  }
}

