@for $v from 1 through 100 {
    [wi-#{$v}] {
        width: #{$v + "%"};
    }
    [he-#{$v}] {
        height: #{$v + "%"};
    }
}

[wi-au] {
    width: auto;
}
