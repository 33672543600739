@import "./dimension.scss";
@import "./padding-margin.scss";
@import "./color-bcg.scss";

/* ACSS 集合 */

// 布局
[di-fl] {
  display: flex;
  align-items: center;
  &[ce] {
    justify-content: center;
  }
  &[sp] {
    justify-content: space-between;
  }
  &[st] {
    justify-content: flex-start;
  }
  &[en] {
    justify-content: flex-end;
  }
}

[di-in-bl] {
  display: inline-block;
}

[di-bl] {
  display: block;
}

// 点击区域扩大
[expand] {
  position: relative;
  display: block;
}

[expand]::before {
  content: "";
  position: absolute;
  left: -10px;
  right: -10px;
  top: -10px;
  bottom: -10px;
}

// 定位
[po-re] {
  position: relative;
}

[po-ab] {
  position: absolute;
}

[po-st] {
  position: sticky;
}

// 超出
[ov-hi] {
  overflow: hidden;
}

// 文字垂直对齐
[ve-al-mi] {
  vertical-align: middle;
}

// 文字水平对齐方式
[te-al-le] {
  text-align: left;
}
[te-al-ce] {
  text-align: center;
}
[te-al-ri] {
  text-align: right;
}

// 圆角
@for $i from 1 through 10 {
  [bo-ra-#{$i}] {
    border-radius: $i + px;
  }
}
// 圆角max
[bo-ra] {
  border-radius: 9999px;
}

// 字体粗细
[fw-bo] {
  font-weight: bold;
}

[zi-99] {
  z-index: 99;
}

// 变换
[tr-ro] {
  transform: rotate(180deg);
}

[te-de-un]{
  text-decoration: underline;
}

// 划过下划线
[ho-te-un] {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.ho-te-un {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

// 划过透明
[ho-op] {
  @include hover-opacity;
}
.ho-op {
  @include hover-opacity;
}

// 全局竖线
[lv-le] {
  @include pseudoVerticalLine(left);
}
[lv-ri] {
  @include pseudoVerticalLine(right);
}
.lv {
  &-le {
    @include pseudoVerticalLine(left);
  }
  &-ri {
    @include pseudoVerticalLine(right);
  }
}

// 浮动
[fl-le] {
  float: left;
}

[fl-ri] {
  float: right;
}

// 字体大小
[fo-si] {
  font-size: var(--font-size);
}

// 字体大小 其他号
@each $i in s, ss, l, ll {
  [fo-si-#{$i}] {
    font-size: var(--font-size-#{$i});
  }
}

// 字体粗细
[fo-we-bo] {
  font-weight: bold;
}

// 超文本省略
[ellipsis] {
  @include ellipsis(1);
}

[bo-sh] {
  box-shadow: 0 0 3px 1px var(--color-shadow-l);
}

@for $i from 0 through 100 {
  [bo-to-le-ra-#{$i}] {
    border-top-left-radius: $i + px;
  }
  [bo-to-ri-ra-#{$i}] {
    border-top-right-radius: $i + px;
  }
  [bo-bo-le-ra-#{$i}] {
    border-bottom-left-radius: $i + px;
  }
  [bo-bo-ri-ra-#{$i}] {
    border-bottom-right-radius: $i + px;
  }
}
