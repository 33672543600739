/*
  vue.config.js css.loaderOptions scss 依赖  
*/
@import "../../styles/settings/index.scss";
@import "../../styles/tools/index.scss";

.ct {
  position: relative;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: 50% 0%;
  font-size: var(--font-size);
  &-main {
    // position: relative;
    // z-index: 1;
    width: 86%;
    max-width: 500px;
    padding: 10px 20px 10px;
    margin: 0 auto;
    background: #fff;
    @include simple-shadow(2, 0, 2px, rgba(0, 0, 0, 0.3));
    border-radius: 5px;
    // top: 10%;
    opacity: 0.98;
  }

  &-navTitle {
    position: relative;
    margin: 16px 0 6px;
    padding-left: 12px;
    font-size: var(--font-size);
    color: var(--color-primary-l);
    border-bottom: 1px solid var(--color-secondary-l);
    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 4px;
      height: 16px;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      // border-radius: 99999px;
      background-color: var(--color-primary);
    }
  }

  &-item {
    @include center;
    justify-content: flex-start;
    padding: 5px 0;
    p {
      &:nth-child(1) {
        margin-right: 12px;
        color: var(--color-secondary-d);
      }
      &:nth-child(2) {
        color: var(--color-primary);
        font-weight: bold;
        margin-right: 6px;
      }
      &.money {
        font-size: var(--font-size-l);
        color: var(--color-warn) !important;
      }
      &.maxPeople {
        color: #999 !important;
      }
    }
  }

  .pay {
    @include center;
    &-btn {
      width: 80%;
      height: 42px;
      border-radius: 9999px;
      background-color: #ec9444;
      color: #fff;
      @include center;
      @include simple-shadow;
      @include hover-opacity;
      margin-top: 24px;
      &.disabled {
        background-color: #eee;
      }
      &.paid {
        opacity: 0.5;
        background-color: #52c41a;
      }
    }
  }

  &-footer {
    // position: absolute;
    // margin-bottom: 20px;
    padding: 5px 0;
    // left: 0;
    // right: 0;
    @include center;
    color: var(--color-secondary);
    font-size: var(--font-size-s);
  }

  &-qrcode {
    width: 80%;
    margin: 10px calc(10%);
    max-height: 420px;
    object-fit: contain;
    @include simple-shadow(2, 0, 0, rgba(0, 0, 0, 0.15));
  }
}

.childInputNumber {
  width: 55px;
  pointer-events: none;
  :global(.ant-input-number-input) {
    text-align: center;
  }
}

.gradeSelect {
  width: 90px;
  flex: 0 0 90px;
  :global(.ant-select-selector) {
    width: 90px;
  }
}

:global {
  .ct-form {
    .ant-form-item-label {
      padding: 0 0 0px !important;
    }
    .ant-form-item {
      margin-bottom: 4px !important;
    }
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      font-size: 18px;
    }
    .ant-form-item-label > label {
      &::after {
        content: "：";
        display: inline-block;
      }
    }
  }
}
