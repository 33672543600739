/*
  vue.config.js css.loaderOptions scss 依赖  
*/
@import "../../styles/settings/index.scss";
@import "../../styles/tools/index.scss";

.ct {
  .wx-avatar {
    border-radius: 9999px;
    @include simple-shadow;
    margin-right: 4px;
  }
}

// 扩展面板
.exp {
  background: white;
  border-bottom: 1px solid var(--color-secondary-l);
  padding: 12px 10px;
  display: flex;
  align-items: flex-start;
  &-panel {
    flex: 0 0 240px;
    padding: 10px;
    &.children {
      flex: 1;
      margin-left: 20px;
    }
  }
  &-row {
    display: flex;
    align-items: center;
    line-height: 24px;
    p {
      &:first-child {
        flex: 0 0 90px;
        text-align: right;
        padding-right: 10px;
        color: var(--color-secondary);
        &::after {
          content: ":";
          display: inline-block;
          font-size: var(--font-size);
        }
      }
      &:last-child {
        flex: 1;
        color: #666;
      }
    }
  }
}

// 来自外部的 样式 后期优化
.out {
  &-navTitle {
    position: relative;
    margin: 0px 0 6px;
    padding-left: 12px;
    font-size: var(--font-size);
    color: var(--color-primary-l);
    border-bottom: 1px solid var(--color-secondary-l);
    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 4px;
      height: 16px;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      // border-radius: 99999px;
      background-color: var(--color-primary);
    }
  }
}
