/*
  vue.config.js css.loaderOptions scss 依赖  
*/
@import "../../styles/settings/index.scss";
@import "../../styles/tools/index.scss";

.btn {
  display: inline-flex;
  align-items: center;
  padding: 4px 16px;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  border-radius: 5px;
  @include hover-opacity;
}