/*
  vue.config.js css.loaderOptions scss 依赖  
*/
@import "../../styles/settings/index.scss";
@import "../../styles/tools/index.scss";

$col-height: 42px;

.formItemRow {
  position: relative;
  &:hover {
    .formItemRow-col {
      visibility: visible;
    }
  }
  &-order {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    margin-bottom: 24px;
    border-radius: 3px;
    background-color: var(--color-primary);
    color: white;
    @include center;
  }
  &-col {
    position: absolute;
    bottom: 0;
    left: -10px;
    right: -48px;
    bottom: -4px;
    height: $col-height;
    line-height: $col-height;
    border-bottom: 1px solid var(--color-primary);
    text-align: right;
    visibility: hidden;
  }
}
