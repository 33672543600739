/*
  vue.config.js css.loaderOptions scss 依赖  
*/
@import "../../styles/settings/index.scss";
@import "../../styles/tools/index.scss";

.userManageAdd {
  :global {
    .ant-row {
      margin-bottom: 12px;
    }
  }
}
