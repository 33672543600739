/**
  表格 样式新增
*/
.el-input {
  &.auto {
    width: auto;
  }
  &.small {
    width: 160px;
  }
  &.medium {
    width: 220px;
  }
  &.large {
    width: 280px;
  }

  &.el-date-editor {
    .el-input__prefix {
      left: 0px;
      padding: 0 5px;
      border: 1px solid #dcdfe6;
      background: #f3f3f3;
    }
    .el-input__inner {
      padding-left: 44px;
    }
  }
}
