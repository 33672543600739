/*
  vue.config.js css.loaderOptions scss 依赖  
*/
@import "styles/settings/index.scss";
@import "styles/tools/index.scss";

#root,
.App,
.ant-layout {
  height: 100%;
}

.ant-layout {
  background: #fff;
}

.ant-layout-content {
  background: #fff;
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background-color: #eee;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
  }
}
