$fields: (
  a: "",
  v: "",
  h: "",
  t: top,
  b: bottom,
  l: left,
  r: right,

);

@each $k, $v in $fields {
  @for $n from 1 through 50 {
    @if $k== v {
      [m#{$k}-#{$n}] {
        margin: $n + px 0;
      }
      [p#{$k}-#{$n}] {
        padding: $n + px 0;
      }
    } @else if $k == h {
      [m#{$k}-#{$n}] {
        margin: 0 $n + px;
      }
      [p#{$k}-#{$n}] {
        padding: 0 $n + px;
      }
    } @else if $k ==a {
      [m#{$k}-#{$n}] {
        margin: $n + px;
      }
      [p#{$k}-#{$n}] {
        padding: $n + px;
      }
    } @else {
      [m#{$k}-#{$n}] {
        margin-#{$v}: $n + px;
      }
      [p#{$k}-#{$n}] {
        padding-#{$v}: $n + px;
      }
    }
  }
}
