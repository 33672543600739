/*
  vue.config.js css.loaderOptions scss 依赖  
*/
@import "../../styles/settings/index.scss";
@import "../../styles/tools/index.scss";

.ant-menu-item {
  .ant-menu-title-content {
    border: 1px solid transparent !important;
  }
}

.ant-menu-item-selected {
  overflow: visible !important;
  .ant-menu-title-content {
    display: block !important;
    border: 1px solid #1890ff !important;
    border-radius: 9999px !important;
    width: auto !important;
    color: #1890ff !important;
    overflow: visible !important;
  }
}

.ant-menu-sub {
  .ant-menu-item {
    padding-left: 48px !important;
  }
  .ant-menu-title-content {
    text-align: center !important;
  }
}

.ant-menu-title-content {
  flex: none !important;
  padding: 0 20px !important;
}

.ant-menu-submenu {
  .ant-menu-title-content {
    padding: 0 12px !important;
  }
}

.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  margin-left: 0px;
}
